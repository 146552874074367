.project-02-intro {
  &__head {
    text-align: center;

    @include mq {
    }
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

.project-02-member {
  &__head {
    text-align: center;
  }
}

.project-02-member-profile {
  margin-top: $ttl02-cont;

  @include mq {
    margin-top: $md-ttl02-cont;
  }
  &__fig {
    background: #d3d3d3;
    /*padding-top: 100%;*/
    border-radius: 50%;
  }

  &__ttl {
    margin-top: $ttl03-cont;
    min-height: 30px;

    @include mq {
      margin-top: $md-ttl03-cont;
      min-height: 40px;
    }
  }
  &__note {
    margin-top: $ttl03-note;

    @include mq {
      margin-top: $md-ttl03-note;
    }
  }
}

.project-02-cont {
  position: relative;
  padding-top: 48px + 24px;

  @include mq {
    padding-top: 80px + 40px;
  }
  &::before {
    content: "";
    background: #707070;
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 48px;

    @include mq {
      width: 2px;
      height: 80px;
      transform: translateX(-50%);
    }
  }

  &--04 {
    &:after {
      content: "";
      background: #ededed;
      position: absolute;
      top: 24px;
      left: 0;
      width: 100vw;
      height: calc(100% - 24px - (400vw / 768 * 100 / 2));
      margin: 0 calc(50% - 50vw);
      z-index: -1;

      @include mq {
        top: 40px;
        height: calc(100% - 40px - (400px / 2));
      }
    }
  }

  &__head {
    text-align: center;

    @include mq {
    }
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

.project-02-fig-01 {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__inner {
    padding-bottom: 48vw / 768 * 100;

    @include mq {
      padding-bottom: 120px;
    }
  }
  &__main {
    background: #d3d3d3;
    position: relative;
    height: 320vw / 768 * 100;

    @include mq {
      height: 385px;
    }
    &::before {
      content: "";
      background: #ededed;
      position: absolute;
      bottom: -48vw / 768 * 100;
      left: 16px;
      width: 100%;
      height: 100%;
      z-index: -1;

      @include mq {
        bottom: -120px;
        left: 264px;
        width: 888px;
        height: 312px;
      }
    }
  }
}

.project-02-fig-01__img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-02-fig-02 {
  margin-top: $fig-cont;
  padding-top: 96vw / 768 * 100;

  @include mq {
    margin-top: $md-fig-cont;
    padding: 0;
  }

  &__inner {
    background: #d3d3d3;
    position: relative;
    height: 320vw / 768 * 100;

    @include mq {
      height: 320px;
    }

    &::before {
      content: "";
      background: #ededed;
      position: absolute;
      top: -96vw / 768 * 100;
      left: -16px;
      width: 552vw / 768 * 100;
      height: 100%;
      z-index: -1;

      @include mq {
        top: -184px;
        left: -128px;
        width: 888px;
        height: 377px;
      }
    }
  }
}

.project-02-fig-03 {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__inner {
    padding-bottom: 32vw / 768 * 100;

    @include mq {
      padding-bottom: 40px;
    }
  }
  &__main {
    background: #d3d3d3;
    position: relative;
    height: 320vw / 768 * 100;

    @include mq {
      height: 320px;
    }
    &::before {
      content: "";
      background: #ededed;
      position: absolute;
      bottom: -32vw / 768 * 100;
      left: -16px;
      width: 100%;
      height: 100%;
      z-index: -1;

      @include mq {
        bottom: -40px;
        left: -88px;
      }
    }
  }
}

.project-02-fig-04 {
  background: #d3d3d3;
  position: relative;
  height: 400vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    height: 400px;
    margin-top: $md-fig-cont;
  }
}

.project-02-others {
  &:not(:first-child) {
    margin-top: $sec-others;

    @include mq {
      margin-top: $md-sec-others;
    }
  }
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      height: 400px;
    }
  }
  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
}

/*フォームstart*/
#contact{
  padding-top: 130px;
  margin: 0 auto;
  max-width: 500px;
}

.form_textbox , .form_commentbox{
  width: 100%;
  padding: 5px;
  margin: 10px;
  border: solid 1px #707070;
  border-radius: 3px;
  background-color: #fff;
  font-size: 0.8em;
}
.form_textbox , .form_commentbox , .form_radio{
  margin-bottom: 40px;
}
.form_commentbox{
  height: 80px;
}
.form_label{
  border-left: solid 4px #7abd38;
  padding: 5px;
  margin-bottom: 5px;
}
.form_label2{
  width: 100%;
}
.form_must{
  padding: 5px;
  background-color: #d25959;
  border-radius: 3px;
  border: solid 1px #fff;
  color: #fff;
  font-size: 0.8em;
  margin-left: 7px;
}
.form_inputs{
  margin-left: 10px;
}
.radio_wrap{
  width: 25%;
  float: left;
}
.confirm_btn{
  background-color: rgba(0,0,255,0);
  color: #3f3f3f;
  font-weight: bold;
  border-radius: 3px;
  border:solid 1px #3f3f3f;
  padding: 10px;
}
.confirm_btn:hover{
  border-bottom: solid 3px #3f3f3f;
  border-right: solid 2px #3f3f3f;
}
#container .confirm_backBtn{
  background-color: rgba(0,0,255,0);
  color: #3f3f3f;
  font-weight: bold;
  border-radius: 3px;
  border:solid 1px #3f3f3f;
  padding: 10px;
  font-size: 0.8em;
}
#container .confirm_backBtn:hover{
  color: #3f3f3f;
  border-bottom: solid 3px #3f3f3f;
  border-right: solid 2px #3f3f3f;
}
/*フォームend*/

/*confirm.php start*/
.confirm_title{
  margin-top: 100px;
  margin-bottom: 30px;
}
.confirmTable_label{
  background-color: #2a66af;
  width: 60px;
  color: #fff;
}
.confirmTable_label{
  border-bottom: dotted 1px #fff;
}
.confirmTable_last{
  border-bottom: none;
}
.confirm_radioWrap{
  width: 50%;
  float: left;
}
.confirm_inputs{
  border-bottom: dotted 1px #3f3f3f;
}
.background_height{
  height: 100vh;
}
/*confirm.php end*/
