.header-03 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  z-index: 1;

  @include mq {
    position: static;
    height: auto;
  }

  &__inner {
    @include flex-bt;

    background: #707070;
    height: 100%;
    padding-left: $grid-side-width;

    @include mq {
      background: none;
      padding: 0;
    }
  }

  &__logo {
    background: url(../img/logo.png) center / cover no-repeat;
    width: 174px * 0.5;
    height: 28px * 0.5;

    @include mq {
      @include flex-center;

      background: #707070;
      position: fixed;
      top: 0;
      left: 0;
      width: 224px;
      height: 80px;
    }
    &:before {
      @include mq {
        content: "";
        background: url(../img/logo.png) center / cover no-repeat;
        width: 144px;
        height: 23px;
      }
    }
  }

  &__drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #707070;
    position: fixed;
    top: $header-height;
    left: 0;
    width: 100%;
    padding: 48px 0;
    transform: translateY(-100%);
    transition: transform 0.3s $ease-out-quint;
    z-index: -1;

    @include mq {
      flex-direction: row;
      justify-content: space-between;
      top: 0;
      left: auto;
      right: 0;
      width: 536px;
      height: 100%;
      padding: 0 80px 0 32px;
      transform: translateX(100%);
      z-index: 1;
    }
  }

  &__btns {
    @include flex-center;

    margin-top: 32px;

    @include mq {
      margin: 0;
    }
  }
}

.header-03-menu {
  @include flex-center;

  flex-direction: column;
  background: #b5b5b5;
  width: $header-height;
  height: 100%;
  padding: 0;
  border: none;

  appearance: none;

  @include mq {
    position: fixed;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    z-index: 2;
  }
}

.header-03-menu-icon {
  @include flex-bt;

  flex-direction: column;
  width: 18px;
  height: 12px;

  @include mq {
  }
  &__line {
    background: #fff;
    height: 1px;
    transition: transform 0.3s $ease-out-quint;

    &:nth-child(1), &:nth-child(3) {
      width: 10px;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
}

.header-03-list {
  @include mq {
    padding-right: 80px;
    border-right: 1px solid #fff;
  }
  &__item {
    &:not(:first-child) {
      margin-top: 32px;

      @include mq {
        margin-top: 48px;
      }
    }
  }
  &__link {
    display: block;
    line-height: 1;
  }

  &__sub {
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #b2b2b2;

    @include mq {
      font-size: 1.3rem;
    }
  }
  &__main {
    margin-top: 8px;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #fff;

    @include mq {
      font-size: 2.4rem;
    }
  }
}

.header-03-btn {
  position: relative;
  width: 80px;
  height: 64px;
  margin-left: 32px;
  border: 1px solid #fff;
  &__txt {
    background: #707070;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 8px;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    transform: translate(-50%, -50%);
  }
}

.js-header.is-active {
  .header-03__drawer {
    transform: translateY(0);
  }
}
