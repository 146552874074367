// 中央寄せ
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-bt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin line-height-crop($line-height) {
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
  }
}

@mixin line-height-crop-md($line-height) {
  &::before {
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
  &::after {
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
  }
}

@mixin hack-ie11 {
  @at-root {
    _:-ms-lang(x)::-ms-backdrop, & {
      @content;
    }
  }
}

// メディアクエリ
$mq-breakpoints: (
  "xsmall": 320px,
  "small": 500px,
  "medium": 768px,
  "main": 950px,
  "large": 1024px,
  "full": 1088px,
  "xlarge": 1280px,
);
@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}
