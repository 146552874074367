.project-01-intro {
  &__head {
    text-align: center;

    @include mq {
    }
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

.project-01-member {
  &__head {
    text-align: center;
  }
}

.project-01-member-profile {
  margin-top: $ttl02-cont;

  @include mq {
    margin-top: $md-ttl02-cont;
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      height: 168px;
    }
  }

  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }

  &__note {
    margin-top: $ttl03-note;

    @include mq {
      margin-top: $md-ttl03-note;
    }
  }
}

.project-01-cont {
  &__head {
    @include flex-center;

    background: #d3d3d3;
    height: 264vw / 768 * 100;

    @include mq {
      height: 264px;
    }
    &--left {
      margin-left: calc(50% - 50vw);
      padding-left: calc(50vw - 50%);
    }
    &--right {
      margin-right: calc(50% - 50vw);
      padding-right: calc(50vw - 50%);
    }
    &--full {
      margin: 0 calc(50% - 50vw);
      padding: 0 calc(50vw - 50%);
    }
  }
  &__body {
    margin-top: $fig-ttl;

    @include mq {
      margin-top: $md-fig-ttl;
    }
  }
  &__main {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
}

.project-01-cont-fig {
  background: #d3d3d3;
  height: 320vw / 768 * 100;
  margin-top: $fig-cont;
  margin-bottom: 100px;

  @include mq {
    height: 320px;
  }
}

.project-01-others {
  &:not(:first-child) {
    margin-top: $sec-others;

    @include mq {
      margin-top: $md-sec-others;
    }
  }
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      height: 240px;
    }
  }
  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
}
