.test {
  &.container {
    background: #d3d3d3;
    padding: 0;
  }
  .container__inner {
    background: #fff;
  }
  .desc {
    position: relative;
    &:not(:first-child) {
      margin-top: 120px;
    }
    &__txt {
      background: #ededed;
      font-size: 4rem;
    }
    &__box {
      &:not(:first-child) {
        margin-top: 48px;

        @include mq {
          margin-top: 96px;
        }
      }
    }
  }

  .col {
    background: #d3d3d3;
    position: relative;
    height: 50px;
  }
}

//以下追記
//トップページnews
.ttl-news{
  margin-bottom: 20px;
}
.table_news_wrapper{
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.table_news_line{
  display: flex;
  justify-content: center;
}
  .table_news_L{
    width: 50%;
    max-width: 235px;
    border-bottom: solid 1px #a9a9a9;
    padding: 10px 10px 10px 20px;
  }
  .table_news_R{
    width: 100%;
    border-bottom: solid 1px #a9a9a9;
    padding: 10px 30px;
  }

//about
.img_about_01{
  width: 100%;
  max-width: 500px;
}
.list_about_01{
  padding-left: 20px;
}

//製品案内
.product_kv {
  background-image: url(../img/img_product_kv.jpg);
  background-position-x: right;
  background-position-y: bottom;
  height: 900vw / 768 * 100;
  // height: 896vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: calc(33vh + 80px);
    margin: 0 calc(50% - 50vw) 0 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: 0 -8px;

    @include mq {
      align-items: flex-end;
      margin: 0 -16px;
    }
  }
}

//空間演出
.soundgarden_kv {
  background-image: url(../img/img_soundgarden_kv.jpg);
  background-position-x: right;
  background-position-y: bottom;
  height: 900vw / 768 * 100;
  // height: 896vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: calc(33vh + 80px);
    margin: 0 calc(50% - 50vw) 0 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: 0 -8px;

    @include mq {
      align-items: flex-end;
      margin: 0 -16px;
    }
  }
}

.soundgarden-01-cont-fig {
  position: relative;
  height: 320vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    height: 440px;
  }
  &__inner {
    background-image: url(../img/img_product_03.jpg);
    background-size: cover;
    height: 100%;

    @include mq {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50vw - 16px);
    }
  }
  &--reverse {
    .soundgarden-01-cont-fig__inner {
      left: auto;
      right: 0;
    }
  }
}

.soundgarden-01-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background-image: url(../img/img_soundgarden_01.jpg);
    background-size: cover;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

.soundgarden-02-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background-image: url(../img/img_soundgarden_02.jpg);
    background-size: cover;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

//プロモーション
.promotion_kv {
  background-image: url(../img/img_promotion_kv.jpg);
  background-position-x: right;
  background-position-y: bottom;
  height: 900vw / 768 * 100;
  // height: 896vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: calc(33vh + 80px);
    margin: 0 calc(50% - 50vw) 0 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: 0 -8px;

    @include mq {
      align-items: flex-end;
      margin: 0 -16px;
    }
  }
}

//LINEプロモーション
.line_kv {
  background-image: url(../img/img_line_kv.jpg);
  background-position-x: right;
  background-position-y: bottom;
  height: 900vw / 768 * 100;
  // height: 896vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: calc(33vh + 80px);
    margin: 0 calc(50% - 50vw) 0 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: 0 -8px;

    @include mq {
      align-items: flex-end;
      margin: 0 -16px;
    }
  }
}

.line-01-cont-fig {
  position: relative;
  height: 320vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    height: 440px;
  }
  &__inner {
    background-image: url(../img/img_line_02.jpg);
    background-size: cover;
    height: 100%;

    @include mq {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50vw - 16px);
    }
  }
  &--reverse {
    .line-01-cont-fig__inner {
      left: auto;
      right: 0;
    }
  }
}

.line-01-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background-image: url(../img/img_line_04.jpg);
    background-size: cover;
    background-position: center;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

.line-02-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background-image: url(../img/img_line_05.jpg);
    background-size: cover;
    background-position: right;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

//映像プロモーション
.movie_kv {
  background-image: url(../img/img_movie_kv.jpg);
  background-position-x: right;
  background-position-y: bottom;
  height: 900vw / 768 * 100;
  // height: 896vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: calc(33vh + 80px);
    margin: 0 calc(50% - 50vw) 0 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: 0 -8px;

    @include mq {
      align-items: flex-end;
      margin: 0 -16px;
    }
  }
}

.movie-01-cont-fig {
  position: relative;
  height: 320vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    height: 440px;
  }
  &__inner {
    background-image: url(../img/img_movie_03.jpg);
    background-size: cover;
    height: 100%;

    @include mq {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50vw - 16px);
    }
  }
  &--reverse {
    .line-01-cont-fig__inner {
      left: auto;
      right: 0;
    }
  }
}

.movie-01-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background-image: url(../img/img_movie_02.jpg);
    background-size: cover;
    background-position: right;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

.movie-02-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background-image: url(../img/img_movie_04.jpg);
    background-size: cover;
    background-position: center;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

//Contact
#mailForm{
  width: 95%;
  margin: 0 auto;

  @include mq {
    width: 70%;
  }
}

/*confirm.php start*/
.table_confirm{
  margin-bottom: 30px;
}
.confirm_label_01, .confirm_label_02{
  background-color: #6b8e23;
  color: #fff;
  padding: 5px 5px 5px 15px;
  border-bottom: dotted 1px #fff;
  min-width: 150px;
  min-height: 50px;
}
.confirm_label_02{
  vertical-align: top;
}
.confirm_inputs_01, .confirm_inputs_02{
  padding: 5px 5px 5px 15px;
  border-bottom: dotted 1px #3f3f3f;
  width: 100%;
  min-height: 50px;
}
.confirm_inputs_02{
  vertical-align: top;
  height: 300px;

}
