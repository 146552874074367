.interview-02-kv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #d3d3d3;
  height: 900vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    position: relative;
    height: (550px - 80px);
    margin: 0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%) $md-ttl01-kv;
  }
}

.interview-02-kv-ttl {
  @include mq {
  }

  &__main {
    @include mq {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__sub {
    margin-top: $ttl01-cont;

    @include mq {
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }
  }
}

.interview-02-kv-profile {
  &__sub {
    font-size: 1rem;
    line-height: 1;

    @include mq {
      font-size: 1.3rem;
    }
  }
  &__main {
    margin-top: 8px;

    @include mq {
      margin-top: 24px;
    }
  }
  &__note {
    margin-top: 8px;

    @include mq {
      margin-top: 24px;
    }
  }
}

.interview-02-cont {
  &__body {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
}

.interview-02-fig-01 {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__inner {
    position: relative;
    padding-bottom: 240vw / 768 * 100;

    @include mq {
      padding-bottom: 80px;
    }
    &::before {
      @include hack-ie11 {
        width: calc(
        (
        100% - #{$grid-gutter-width} * (#{$grid-columns} - 1) - (
        #{$grid-gutter-width} / 2
        ) * 2
        ) * 9 / #{$grid-columns} + #{$grid-gutter-width} * (9 - 1) - 0.1px
        );
      }

      content: "";
      background: #ededed;
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(
      (
      100% - #{$grid-gutter-width} * (#{$grid-columns} - 1) - (
      #{$grid-gutter-width} / 2
      ) * 2
      ) * 9 / #{$grid-columns} + #{$grid-gutter-width} * (9 - 1)
      );
      height: 360vw / 768 * 100;
      z-index: -1;

      @include mq {
        @include hack-ie11 {
          width: calc(100% - #{$md-grid-gutter-width} * 2);
        }

        left: 32px;
        width: calc(100% - #{$md-grid-gutter-width} * 2);
        height: 500px;
        max-width: calc(
        #{$md-grid-column-width} * 7 + #{$md-grid-gutter-width} * (7 - 1)
        );
      }
    }
  }
  &__main {
    background: #d3d3d3;
    height: 360vw / 768 * 100;

    @include mq {
      height: 500px;
    }
  }
}

.interview-02-fig-02 {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }

  &__inner {
    @include mq {
      position: relative;
      padding-bottom: 264px;
    }
    &::before {
      @include mq {
        content: "";
        background: #ededed;
        position: absolute;
        bottom: 0;
        right: 32px;
        width: calc(100% - #{$md-grid-gutter-width} * 2);
        height: 360px;
        max-width: calc(
        #{$md-grid-column-width} * 9 + #{$md-grid-gutter-width} * (9 - 1)
        );
        z-index: -1;
      }
    }
  }
  &__box {
    position: relative;
    height: 360vw / 768 * 100;

    @include mq {
      height: 248px;
    }
  }
  &__main {
    background: #d3d3d3;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 16px);
    height: 100%;

    @include mq {
      width: 100%;
    }
  }
}

.interview-02-fig-03 {
  background: #d3d3d3;
  height: 420vw / 768 * 100;
  margin: $fig-cont calc(50% - 50vw) 0;

  @include mq {
    height: 360px;
    margin: $md-fig-cont 0 0;
  }
}

.interview-02-others {
  &:not(:first-child) {
    margin-top: $sec-others;

    @include mq {
      margin-top: $md-sec-others;
    }
  }
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
  &__item {
    @include mq {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      width: calc(
      (100% - #{$md-grid-gutter-width} * (#{$grid-columns} - 1)) * 8 / #{$grid-columns} +
      #{$md-grid-gutter-width} * (8 - 1)
      );
      height: 280px;
      padding: 0;
    }
  }
  &__cont {
    @include mq {
      width: calc(
      (100% - #{$md-grid-gutter-width} * (#{$grid-columns} - 1)) * 4 / #{$grid-columns} +
      #{$md-grid-gutter-width} * (4 - 1)
      );
    }
  }
  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin: 0;
    }
  }
  &__note {
    margin-top: $ttl03-note;

    @include mq {
      margin-top: $md-ttl03-note;
    }
  }
}
