*, *:before, *:after {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
  "Hiragino Sans", "BIZ UDPGothic", Meiryo, sans-serif;
  font-size: 1.2rem;
  line-height: (44 / 24);
  letter-spacing: 0.1em;
  color: #333;
  word-wrap: break-word;

  font-feature-settings: "palt";
  /* フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;

  @include mq {
    font-size: 1.4rem;
    line-height: (32 / 14);
    letter-spacing: 0.05em;
  }
}

ul, li {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}
input {
  vertical-align: bottom;
  margin: 0;
  padding: 0;
}

@include mq {
  .br-pc::before {
    content: "\A";
    white-space: pre;
  }
  .br-sp::before {
    content: "";
    white-space: normal;
  }
}

.br-sp::before {
  content: "\A";
  white-space: pre;
}

.br::before {
  content: "\A";
  white-space: pre;
}

.fadein {
  // transform: translate(0, 30px);
  transition: all 300ms ease-out;
  opacity: 0;

  @include mq {
    // opacity: 1;
  }
}

.fadein.on {
  opacity: 1;
  // transform: translate(0, 0);
}
