.header-02 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  z-index: 1;

  @include mq {
    width: 80px;
    height: 100%;
  }
  &__inner {
    @include flex-bt;

    background: #707070;
    height: 100%;
    padding-left: $grid-side-width;

    @include mq {
      flex-direction: column;
      padding: 24px 0 0 0;
    }
  }
  &__logo {
    background: url(../img/logo.png) center / cover no-repeat;
    width: 174px * 0.5;
    height: 28px * 0.5;

    @include mq {
      width: 144px * 0.5;
      height: 23px * 0.5;
    }
  }
  &__btns {
    @include flex-bt;

    height: 100%;

    @include mq {
      width: 100%;
      height: auto;
    }
  }
  &__btn {
    @include flex-center;

    width: 72px;
    height: 24px;
    border: 1px solid #fff;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #fff;

    @include mq {
      background: #d3d3d3;
      width: 100%;
      height: 80px;
      border: 0;
      color: #333;
    }
  }
  &__menu {
    margin-left: 16px;

    @include mq {
      position: fixed;
      top: 50%;
      width: 80px;
      height: 80px;
      margin: 0;
      transform: translateY(-50%);
    }
  }
  &__drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #707070;
    position: fixed;
    top: $header-height;
    left: 0;
    width: 100%;
    padding: 48px 0;
    transform: translateY(-100%);
    transition: transform 0.3s $ease-out-quint;
    z-index: -1;

    @include mq {
      flex-direction: row;
      background: rgba(#707070, 0.9);
      top: 0;
      left: 80px;
      width: 368px;
      height: 100%;
      padding: 0 0 0 48px;
      transform: translateX(-100%);
    }
  }
}

.header-02-list {
  &__item {
    &:not(:first-child) {
      margin-top: 32px;

      @include mq {
        margin-top: 48px;
      }
    }
  }
  &__link {
    display: block;
    line-height: 1;
  }

  &__sub {
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #b2b2b2;

    @include mq {
      font-size: 1.3rem;
    }
  }
  &__main {
    margin-top: 8px;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #fff;

    @include mq {
      font-size: 2.4rem;
    }
  }
}

.header-02-menu {
  @include flex-center;

  flex-direction: column;
  background: #b5b5b5;
  width: $header-height;
  height: 100%;
  padding: 0;
  border: none;

  appearance: none;

  @include mq {
    background: none;
    font-size: 1.3rem;
    line-height: 1;
    color: #fff;
    &::after {
      content: "MENU";
      margin-top: 12px;
    }
  }
}

.header-02-menu-icon {
  @include flex-bt;

  flex-direction: column;
  width: 18px;
  height: 12px;

  @include mq {
    flex-direction: row;
    width: 13px;
    height: 20px;
  }
  &__line {
    background: #fff;
    height: 1px;
    transition: transform 0.3s $ease-out-quint;

    @include mq {
      height: 100%;
    }

    &:nth-child(1), &:nth-child(3) {
      width: 10px;

      @include mq {
        width: 1px;
      }
    }
    &:nth-child(2) {
      width: 100%;

      @include mq {
        width: 1px;
      }
    }
  }
}

.js-header.is-active {
  .header-02__drawer {
    transform: translateY(0);
  }

  .header-02-menu {
    &:after {
      @include mq {
        content: "CLOSE";
      }
    }
  }

  .header-02-menu-icon {
    &__line {
      &:nth-child(1) {
        @include mq {
          transform: translateX(6px) rotate(30deg);
        }
      }
      &:nth-child(2) {
        @include mq {
          opacity: 0;
        }
      }
      &:nth-child(3) {
        @include mq {
          transform: translateX(-6px) rotate(-30deg);
        }
      }
    }
  }
}
