.interview-03-kv {
  &__cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $sec-sec;

    @include mq {
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.interview-03-kv-fig {
  position: relative;
  height: 550vw / 768 * 100;

  @include mq {
    height: 550px;
  }
  &__inner {
    background: #d3d3d3;
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    margin: 0 calc(50% - 50vw);

    @include mq {
      width: calc(50vw + 88px);
      margin: 0;
    }
  }
}

.interview-03-kv-profile {
  margin-top: $ttl02-cont;

  @include mq {
    margin-top: $md-ttl02-cont;
  }
  &__sub {
    font-size: 1rem;
    line-height: 1;

    @include mq {
      font-size: 1.3rem;
    }
  }
  &__main {
    margin-top: 8px;

    @include mq {
      margin-top: 24px;
    }
  }
  &__note {
    margin-top: 8px;

    @include mq {
      margin-top: 24px;
    }
  }
}

.interview-03-cont {
  &__txt {
    margin-top: $ttl03-cont;
  }
}

.interview-03-cont-ttl {
  &__sub {
    font-size: 1rem;
    line-height: 1;

    @include mq {
      font-size: 1.3rem;
    }
  }
  &__main {
    margin-top: 8px;
  }
}

.interview-03-cont-fig {
  position: relative;
  height: 320vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    position: sticky;
    top: $md-header-height;
    height: calc(100vh - #{$md-header-height});
  }
  &--01 {
    .interview-03-cont-fig__inner {
      background: url(../img/dummy.jpg) center / cover no-repeat;
    }
  }
  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @include mq {
      width: calc(50vw + 88px);
    }
  }
}

.interview-03-others {
  &:not(:first-child) {
    margin-top: $sec-others;

    @include mq {
      margin-top: $md-sec-others;
    }
  }
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      height: 240px;
    }
  }
  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
  &__note {
    margin-top: $ttl03-note;

    @include mq {
      margin-top: $md-ttl03-note;
    }
  }
}
