.footer-01 {
  @include flex-center;

  flex-direction: column;
  background: #7abd38;
  margin-top: $others-footer;
  padding: (112px * 0.5) 0 16px;
  color: #fff;

  @include mq {
    margin-top: $md-others-footer;
    padding: 72px 0 24px;
  }
  &--layout-02 {
    @include mq {
      margin: $others-footer calc(50% - (50vw - 40px)) 0;
      padding: 72px calc((50vw - 40px) - 50%) 24px;
    }
  }
  &__logo {
    background: url(../img/logo.png) center / cover no-repeat;
    width: 139px;
    height: 38px;

    @include mq {
      width: 170px;
      height: 45px;
    }
  }

  &__copyright {
    @include line-height-crop((24 / 18));

    margin-top: 32px;
    font-size: 1rem;
    line-height: (24 / 18);
    text-align: center;

    @include mq {
      @include line-height-crop((24 / 12));

      font-size: 1.2rem;
      line-height: (24 / 12);
    }
  }
}

.footer-01-links {
  margin-top: 40px;

  @include mq {
    display: flex;
    margin-top: 56px;
  }
  &__item {
    padding: 0 16px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    line-height: 1;

    @include mq {
      border-right: 0;
    }
    &:not(:first-child) {
      margin-top: 24px;

      @include mq {
        margin: 0;
      }
    }
    &:last-child {
      @include mq {
        border-right: 1px solid #fff;
      }
    }
  }
  &__ttl {
    font-size: 1rem;
    color: #fff;

    @include mq {
      font-size: 1.3rem;
    }
    &::after {
      content: "";
      display: inline-block;
      background: url(../img/icon_external_link_01.svg) center / cover no-repeat;
      width: round(20px * 0.5);
      height: round(15px * 0.5);
      margin-left: 8px;

      @include mq {
        width: 12px;
        height: 9px;
      }
    }
  }
}
