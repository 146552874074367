.project-03-kv {
  background: #d3d3d3;
  height: 900vw / 768 * 100;
  // height: 896vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: (550px - 80px);
    margin: 0 calc(50% - 50vw) 0 0;
    padding: 0 calc(50vw - 50%) $md-ttl01-kv 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: 0 -8px;

    @include mq {
      align-items: flex-end;
      margin: 0 -16px;
    }
  }
}

.project-03-kv-ttl {
  margin: 0 8px;

  @include mq {
    margin: 0 16px;
  }
  &__sub {
    @include mq {
    }
  }
  &__main {
    margin-top: $ttl01-ttl03;

    @include mq {
      margin-top: $md-ttl01-ttl03;
    }
  }
}

.project-03-intro {
  &__head {
    text-align: center;

    @include mq {
    }
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

.project-03-member {
  &__head {
    text-align: center;
  }
}

.project-03-member-profile {
  margin-top: $ttl02-cont;

  @include mq {
    margin-top: $md-ttl02-cont;
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      height: 168px;
    }
  }

  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }

  &__note {
    margin-top: $ttl03-note;

    @include mq {
      margin-top: $md-ttl03-note;
    }
  }
}

.project-03-cont {
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      display: grid;
      margin-top: $md-ttl02-cont;

      column-gap: 32px;
      grid-template: "fig top_txt" auto
      "fig bottom_txt" 1fr /
      1fr 1fr;
      row-gap: 32px + (32px - 14px); // 改行 + 文字の上下につく余白;
    }
    &--md-reverse {
      @include mq {
        column-gap: 32px;
        grid-template: "top_txt fig" auto
        "bottom_txt fig" 1fr /
        1fr 1fr;
        row-gap: 32px + (32px - 14px); // 改行 + 文字の上下につく余白;
      }
    }
  }

  &__txt {
    &:not(:first-child) {
      margin-top: $fig-cont;

      @include mq {
        margin: 0;
      }
    }
    &--top {
      @include mq {
        grid-area: top_txt;
      }
    }
    &--bottom {
      @include mq {
        grid-area: bottom_txt;
      }
    }
  }
}

.project-03-cont-fig {
  background: #d3d3d3;
  height: 400vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    width: 100%;
    height: 600px;
    margin: 0;

    grid-area: fig;
  }
}

.project-03-figs {
  &__item {
    &:first-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

.project-03-others {
  &:not(:first-child) {
    margin-top: $sec-others;

    @include mq {
      margin-top: $md-sec-others;
    }
  }
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 250vw / 768 * 100;

    @include mq {
      height: 240px;
    }
  }
  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
}
