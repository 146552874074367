.project-kv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 100vh;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%)
  $ttl01-kv; color: #fff;
  background-color: rgba(0,0,0,0.2);

  @include mq {
    height: 100vh;
    padding: 0 calc(50vw - 50%)
    $md-ttl01-kv;
  }
}
.project-kv__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.project-kv-ttl {
  &__sub {
    @include mq {
    }
  }
  &__main {
    margin-top: $ttl01-ttl03;

    @include mq {
      margin-top: $md-ttl01-ttl03;
    }
  }
}
