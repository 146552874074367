.interview-01-kv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #d3d3d3;
  height: 900vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%) $ttl01-kv;

  @include mq {
    height: (550px - 80px);
    margin: 0 0 0 calc(50% - 50vw);
    padding: 0 0 $md-ttl01-kv calc(50vw - 50%);
  }
}

.interview-01-kv-ttl {
  &__main {
    @include mq {
    }
  }
}

.interview-01-kv-profile {
  margin-top: $ttl01-cont;

  @include mq {
    margin-top: $md-ttl01-cont;
  }
  &__sub {
    font-size: 1rem;
    line-height: 1;

    @include mq {
      font-size: 1.3rem;
    }
  }
  &__main {
    margin-top: 8px;

    @include mq {
      margin-top: 24px;
    }
  }
  &__note {
    margin-top: 8px;

    @include mq {
      margin-top: 24px;
    }
  }
}

.interview-01-cont {
  &__body {
    margin-top: $fig-ttl;

    @include mq {
      margin-top: $md-fig-ttl;
    }
  }
  &__main {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
}

.interview-01-cont-fig {
  position: relative;
  height: 320vw / 768 * 100;
  margin-top: $fig-cont;

  @include mq {
    height: 440px;
  }
  &__inner {
    background: #d3d3d3;
    height: 100%;

    @include mq {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50vw - 16px);
    }
  }
  &--reverse {
    .interview-01-cont-fig__inner {
      left: auto;
      right: 0;
    }
  }
}

.interview-01-fig {
  background: #d3d3d3;
  height: 280vw / 768 * 100;

  @include mq {
    height: 368px;
  }
  &--left {
    margin-left: calc(50% - 50vw);
    padding-left: calc(50vw - 50%);
  }
  &--right {
    margin-right: calc(50% - 50vw);
    padding-right: calc(50vw - 50%);
  }
  &--full {
    margin: 0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%);
  }
}

.interview-01-figs {
  margin-top: $fig-cont;

  @include mq {
    margin-top: $md-fig-cont;
  }
  &__item {
    &:last-child {
      padding-top: 64vw / 768 * 100;

      @include mq {
        padding-top: 64px;
      }
    }
  }
  &__fig {
    background: #d3d3d3;
    height: 400vw / 768 * 100;

    @include mq {
      height: 320px;
    }
  }
}

.interview-01-schedule {
  &__head {
    text-align: center;

    @include mq {
    }
  }
  &__body {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
  &__inner {
    @include mq {
    }
  }
  &__item {
    @include flex-bt;

    @include mq {
      display: block;
    }
    &:not(:first-child) {
      margin-top: 24px;

      @include mq {
        margin: 64px 16px 0;
      }
      .interview-01-schedule-time {
        &:before {
          content: "";
          background: #f4f4f4;
          position: absolute;
          bottom: 100%;
          left: 50%;
          width: 10px;
          height: 24px + 2px * 2;
          transform: translateX(-50%);
          z-index: -1;

          @include mq {
            top: 50%;
            bottom: auto;
            left: auto;
            right: 100%;
            width: 32px + 4px * 2;
            height: 20px;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }

  &__cont {
    width: calc(100% - (16px + 212px * 0.5));

    @include mq {
      width: 100%;
      margin-top: 24px;
    }
  }
  &__ttl {
    @include line-height-crop((42 / 26));

    font-size: 1.3rem;
    line-height: (42 / 26);

    @include mq {
      @include line-height-crop-md((32 / 16));

      font-size: 1.6rem;
      line-height: (32 / 16);
      text-align: center;
    }
  }
  &__txt {
    margin-top: 16px;

    @include mq {
      margin-top: 24px;
    }
  }
}

.interview-01-schedule-time {
  position: relative;
  width: 212px * 0.5;
  padding-top: 212px * 0.5 - 2px * 2;
  border: 2px solid #d3d3d3;
  border-radius: 50%;

  @include mq {
    width: 100%;
    padding-top: calc(100% - 4px * 2);
    border: 4px solid #d3d3d3;
  }
  &__txt {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    transform: translate(-50%, -50%);

    @include mq {
      font-size: 2.6rem;
    }
  }
}

.interview-01-others {
  &:not(:first-child) {
    margin-top: $sec-others;

    @include mq {
      margin-top: $md-sec-others;
    }
  }
  &__head {
    text-align: center;
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
  &__fig {
    background: #d3d3d3;
    padding-top: 100%;

    @include mq {
      height: 168px;
      padding: 0;
    }
  }
  &__ttl {
    margin-top: $ttl03-cont;

    @include mq {
      margin-top: $md-ttl03-cont;
    }
  }
  &__note {
    margin-top: $ttl03-note;

    @include mq {
      margin-top: $md-ttl03-note;
    }
  }
}
