// http://photoshopvip.net/122332

.container {
  /*padding-top: $header-height;*/
  &--layout-01 {
    @include mq {
      /*padding-top: $md-header-height;*/
    }
  }
  &--layout-02 {
    @include mq {
      width: calc(100% - 80px);
      margin-left: auto;
      padding: 0;
    }
  }
  &--layout-03 {
    @include mq {
      padding: 0;
    }
  }

  &__inner {
    padding: 0 $grid-side-width;

    @include mq {
      max-width: $md-grid-column-width * $grid-columns + $md-grid-gutter-width *
      ($grid-columns - 1) + $md-grid-side-width * 2;
      margin: 0 auto;
      padding: 0 $md-grid-side-width;
    }
  }
}

.cont {
  margin-top: $sec-sec;

  @include mq {
    margin-top: $md-sec-sec;
  }
  &__item {
    &:not(:first-child) {
      margin-top: $sec-sec;

      @include mq {
        margin-top: $md-sec-sec;
      }
    }
  }
}

.row {
  display: flex;
  flex-flow: wrap;
  margin: (-$col-col) (-$grid-gutter-width / 2) 0;

  @include mq {
    margin: (-$md-col-col) (-$md-grid-gutter-width / 2) 0;
  }

  &--md-multi {
    @include mq {
      margin: (-$md-col-col) (-$md-grid-gutter-width) 0;
    }

    .col {
      @include mq {
        width: calc(100% - #{$md-grid-gutter-width} * 2);
        margin: ($md-col-col) $md-grid-gutter-width 0;
      }
      &:not(:first-child) {
        @include mq {
          margin: ($md-col-col) $md-grid-gutter-width 0 auto;
        }
      }
    }
  }
  &--md-multi-center {
    justify-content: center;
    .col {
      @include mq {
        width: calc(100% - (#{$md-grid-gutter-width} / 2) * 2);
      }
    }
  }
  &--md-multi-end {
    @include mq {
      flex-direction: row-reverse;
      margin: (-$md-col-col) (-$md-grid-gutter-width) 0;
    }

    .col {
      @include mq {
        width: calc(100% - (#{$md-grid-gutter-width} / 2) * 2);
        margin: ($md-col-col) $md-grid-gutter-width 0;
      }
      &:not(:first-child) {
        @include mq {
          margin: ($md-col-col) auto 0 $md-grid-gutter-width;
        }
      }
    }
  }

  // justify-content
  &--space-between {
    justify-content: space-between;
  }
  &--md-space-between {
    justify-content: space-between;
  }
  // &--start {
  //   justify-content: flex-start;
  // }
  &--md-start {
    @include mq {
      justify-content: flex-start;
    }
  }
  &--center {
    justify-content: center;
  }
  &--md-center {
    @include mq {
      justify-content: center;
    }
  }
  &--end {
    justify-content: flex-end;
  }
  &--md-end {
    @include mq {
      justify-content: flex-end;
    }
  }
  // align-items
  // &--v-start {
  //   align-items: flex-start;
  // }
  &--md-v-start {
    @include mq {
      align-items: flex-start;
    }
  }
  &--v-center {
    align-items: center;
  }
  &--md-v-center {
    @include mq {
      align-items: center;
    }
  }
  &--v-end {
    align-items: flex-end;
  }
  &--md-v-end {
    @include mq {
      align-items: flex-end;
    }
  }

  // flex-direction
  &--reverse {
    flex-direction: row-reverse;
  }
  &--md-reverse {
    @include mq {
      flex-direction: row-reverse;
    }
  }
}

.col {
  margin: $col-col ($grid-gutter-width / 2) 0;

  @include mq {
    margin: $md-col-col ($md-grid-gutter-width / 2) 0;
  }

  @for $i from 1 through $grid-columns {
    &--#{$i} {
      @include hack-ie11 {
        width: calc(
        (
        100% - #{$grid-gutter-width} * (#{$grid-columns} - 1) - (
        #{$grid-gutter-width} / 2
        ) * 2
        ) * #{$i} / #{$grid-columns} + #{$grid-gutter-width} * (#{$i} - 1) -
        0.1px
        );
      }

      width: calc(
      (
      100% -
      #{$grid-gutter-width} *
      (#{$grid-columns} - 1) -
      (#{$grid-gutter-width} / 2) *
      2
      ) *
      #{$i} /
      #{$grid-columns} +
      #{$grid-gutter-width} *
      (#{$i} - 1)
      );

      @include mq {
        @include hack-ie11 {
          width: calc(
          (
          100% - #{$md-grid-gutter-width} * (#{$grid-columns} - 1) - (
          #{$md-grid-gutter-width} / 2
          ) * 2
          ) * #{$i} / #{$grid-columns} + #{$md-grid-gutter-width} * (
          #{$i} - 1
          ) - 0.1px
          );
        }

        width: calc(
        (
        100% - #{$md-grid-gutter-width} * (#{$grid-columns} - 1) - (
        #{$md-grid-gutter-width} / 2
        ) * 2
        ) * #{$i} / #{$grid-columns} + #{$md-grid-gutter-width} * (#{$i} - 1)
        );
      }
    }
  }

  &--md {
    @for $i from 1 through $grid-columns {
      &-#{$i} {
        @include mq {
          @include hack-ie11 {
            width: calc(
            (
            100% - #{$md-grid-gutter-width} * (#{$grid-columns} - 1) - (
            #{$md-grid-gutter-width} / 2
            ) * 2
            ) * #{$i} / #{$grid-columns} + #{$md-grid-gutter-width} * (
            #{$i} - 1
            ) - 0.1px
            );
          }

          width: calc(
          (
          100% - #{$md-grid-gutter-width} * (#{$grid-columns} - 1) - (
          #{$md-grid-gutter-width} / 2
          ) * 2
          ) * #{$i} / #{$grid-columns} + #{$md-grid-gutter-width} * (#{$i} -
          1)
          );
          max-width: calc(
          #{$md-grid-column-width} * #{$i} + #{$md-grid-gutter-width} * (#{$i} -
          1)
          );
        }
      }
    }
  }
}

.ttl-01 {
  @include line-height-crop((60 / 40));

  font-size: 2rem;
  line-height: (60 / 40);
  letter-spacing: 0.1em;

  @include mq {
    @include line-height-crop-md((70 / 48));

    font-size: 3.5rem;
    line-height: (70 / 48);
    letter-spacing: 0.1em;
  }
}

.ttl-02 {
  @include line-height-crop((50 / 34));

  font-size: 3rem;
  line-height: (50 / 34);
  letter-spacing: 0.1em;

  @include mq {
    @include line-height-crop-md((58 / 36));

    font-size: 3.6rem;
    line-height: (58 / 36);
    letter-spacing: 0.1em;
  }
}

.ttl-03 {
  @include line-height-crop((42 / 28));

  font-size: 2rem;
  line-height: (42 / 28);
  letter-spacing: 0.1em;

  @include mq {
    @include line-height-crop-md((42 / 26));

    font-size: 2.6rem;
    line-height: (42 / 26);
    letter-spacing: 0.1em;
  }
}
//追記start
.ttl-04 {
  @include line-height-crop((60 / 40));

  font-size: 3rem;
  line-height: (60 / 40);
  letter-spacing: 0.1em;
  color: #fff;

  @include mq {
    @include line-height-crop-md((70 / 48));

    font-size: 4rem;
    line-height: (70 / 48);
    letter-spacing: 0.1em;
  }
}

.ttl-05 {
  @include line-height-crop((42 / 28));

  font-size: 2rem;
  line-height: (42 / 28);
  letter-spacing: 0.1em;
  border-left: solid 5px #008000;
  padding-left: 15px;
  margin-top: 30px;
  margin-bottom: 20px;

  @include mq {
    @include line-height-crop-md((42 / 26));

    font-size: 2.6rem;
    line-height: (42 / 26);
    letter-spacing: 0.1em;
  }
}
.ttl-06 {
  @include line-height-crop((42 / 28));

  font-size: 2rem;
  line-height: (42 / 28);
  letter-spacing: 0.1em;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 50px;

  @include mq {
    @include line-height-crop-md((42 / 26));

    font-size: 2.6rem;
    line-height: (42 / 26);
    letter-spacing: 0.1em;
  }
}
//追記end

.txt {
  @include line-height-crop((44 / 24));

  @include mq {
    @include line-height-crop-md((32 / 14));
  }
}
//追記start
.txt-01{
  padding-top: 10px;
  padding-bottom: 10px;
}
.txt-02{
  font-size: 10px;
  color: #808080;
}
.txt-03{
  width: 95%;
  margin: 0px auto 30px;
  @include mq{
    text-align: center;
  }
}
.txt-04{
  margin-top: 30px;
  margin-bottom: 20px;
}
//追記end

.note {
  @include line-height-crop((32 / 20));

  font-size: 1rem;
  line-height: (32 / 20);

  @include mq {
    @include line-height-crop-md((24 / 13));

    font-size: 1.3rem;
    line-height: (24 / 13);
  }
}

/*追記start*/
.tac{
  text-align: center;
}
.red{
  color: #d25959;
}
.btn_01{
  width: 230px;
  color: #fff;
  background-color: #7abd38;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: 10px;
  @include mq{
    margin-top: 70px;
  }
}
.youtube{
  width: 100%;
  aspect-ratio: 16/9;
}
.youtube iframe{
  width: 100%;
  height: 100%;
}

/*追記end*/
