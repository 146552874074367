.information-02-kv {
  @include flex-center;

  background: #d3d3d3;
  height: 450vw / 768 * 100;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);

  @include mq {
    height: 550px;
  }
}

.information-02-intro {
  &__head {
    text-align: center;

    @include mq {
    }
  }
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

// .information-02-requirements {
//   &__body {
//     margin-top: $ttl02-cont;

//     @include mq {
//       margin-top: $md-ttl02-cont;
//     }
//   }
// }

.information-02-requirements-list {
  &__item {
    padding: 24px 0;
    border-top: 1px solid #d3d3d3;

    @include mq {
      padding: 40px 0;
    }
    &:last-child {
      border-bottom: 1px solid #d3d3d3;
    }
  }
  &__head {
    @include line-height-crop((44 / 34));

    position: relative;
    padding-left: 16px + 2px;
    font-size: 1.7rem;
    line-height: (44 / 34);
    letter-spacing: 0.1em;

    @include mq {
      @include line-height-crop-md((32 / 26));

      padding-left: 24px + 4px;
      font-size: 2.6rem;
      line-height: (32 / 26);
    }
  }
  &__line {
    background: #333;
    position: absolute;
    top: round((44px - 34px) / 2 / 2);
    left: 0;
    width: 2px;
    height: 34px * 0.5;

    @include mq {
      top: round((32px - 26px) / 2);
      width: 4px;
      height: 26px;
    }
  }
  &__body {
    margin-top: 16px;

    @include mq {
      margin-top: 40px;
    }
  }
  &__txt {
    @include line-height-crop((44 / 24));

    @include mq {
      @include line-height-crop-md((26 / 16));

      font-size: 1.6rem;
      line-height: (26 / 16);
    }
  }
  &__note {
    margin-top: 8px;

    @include mq {
      margin-top: 16px;
    }
  }
}

.information-02-flow {
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

.information-02-flow-list {
  @include mq {
    display: flex;
    height: 160px;
  }
  &__item {
    @include flex-center;

    position: relative;

    @include mq {
      width: (100% - 22.5 * 2) * 0.5;
    }
    &:not(:first-child) {
      padding: (8px + 24px) 0 24px;
      border-top: 1px solid #707070;

      @include mq {
        padding: 0 24px 0 (8px + 24px);
        border-top: 0;
        border-left: 1px solid #707070;
      }
      &:before {
        content: "";
        background: #fff;
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 12px;
        height: 12px;
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;
        transform: translate(-50%, 50%) rotate(45deg);

        @include mq {
          top: 50%;
          bottom: auto;
          left: auto;
          right: 100%;
          transform: translate(50%, -50%) rotate(-45deg);
        }
      }
    }
    &:first-child {
      padding-bottom: 24px;

      @include mq {
        justify-content: flex-start;
        width: 22.5%;
        padding: 0 24px 0 0;
      }
    }
    &:last-child {
      padding: (8px + 24px) 0 0;

      @include mq {
        justify-content: flex-start;
        width: 22.5%;
        padding: 0 0 0 (8px + 24px);
      }
    }
  }
  &__num {
    font-size: 1rem;
    line-height: 1;

    @include mq {
      font-size: 1.6rem;
    }
  }
  &__txt {
    margin-top: 16px;
    font-size: 1.3rem;
    line-height: 1;

    @include mq {
      font-size: 2.6rem;
    }
  }
}

.information-02-faq {
  &__body {
    margin-top: $ttl02-cont;

    @include mq {
      margin-top: $md-ttl02-cont;
    }
  }
}

.information-02-faq-list {
  &__item {
    padding: 24px 0;
    border-top: 1px solid #d3d3d3;
    font-size: 1.3rem;
    line-height: (38 / 26);

    @include mq {
      padding: 40px 0;
      font-size: 1.6rem;
      line-height: (26 / 16);
    }

    &:last-child {
      border-bottom: 1px solid #d3d3d3;
    }
  }

  &__question {
    @include line-height-crop((38 / 26));

    @include mq {
      @include line-height-crop-md((26 / 16));
    }
  }
  &__answer {
    @include line-height-crop((38 / 26));

    margin-top: 16px;
    font-weight: bold;

    @include mq {
      @include line-height-crop-md((26 / 16));
    }
  }
}
