.header-01 {
  background-color: rgba(255,255,255,1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 1px #a9a9a9;
  height: 60px;
  padding-left: $grid-side-width;
  z-index: 1;

  @include mq {
    height: $md-header-height;
    padding: 0 $md-grid-side-width;
  }
  &__inner {
    @include flex-bt;

    height: 100%;
    max-width: $md-grid-column-width * $grid-columns + $md-grid-gutter-width *
    ($grid-columns - 1);
    margin: 0 auto;
  }
  &__logo {
    background: url(../img/logo.png) center / cover no-repeat;
    width: 150px;
    height: 50px;

    @include mq {
      width: 144px;
      height: 43px;
    }
  }
  &__links {
    display: flex;
    height: 100%;
  }
  &__btns {
    @include flex-bt;

    height: 100%;

    @include mq {
      margin-left: 24px;
    }
    @include mq($breakpoint: large) {
      margin-left: 48px;
    }
  }
  &__btn {
    @include flex-center;

    width: 100px;
    height: 24px;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #fff;
    background-color: #7abd38;

    @include mq {
      width: 100px;
      height: 32px;
    }
    @include mq($breakpoint: large) {
      width: 120px;
    }
  }
  &__menu {
    margin-left: 16px;

    @include mq {
    }
  }
}

.header-01-list {
  position: absolute;
  top: $header-height;
  left: 0;
  width: 100%;
  padding: 48px 0;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  background-color: rgba(181,181,181,0.9);

  @include mq {
    display: flex;
    position: static;
    padding: 0;
    visibility: visible;
    opacity: 1;
    background-color: #fff;
  }
  &__item {
    border-top: 1px solid #fff;

    @include mq {
      border: 0;
    }
    &:last-child {
      border-bottom: 1px solid #fff;

      @include mq {
        border: 0;
      }
    }
    &:not(:first-child) {
      @include mq {
        margin-left: 24px;
      }
      @include mq($breakpoint: large) {
        margin-left: 48px;
      }
    }

    &--accordion {
      .header-01-sub-list__item {
        &:first-child {
          @include mq {
            display: none;
          }
        }
      }

      .header-01-list__link {
        display: none;

        @include mq {
          display: flex;
        }
        &:hover {
          @include mq {
            & + .header-01-accordion {
              .header-01-accordion__body {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }

      .header-01-accordion {
        &:hover {
          @include mq {
            .header-01-accordion__body {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &__link {
    display: block;
    padding: 16px;
    font-size: 1.4rem;
    line-height: 1;
    letter-spacing: 0.1em;
    color: #fff;

    @include mq {
      @include flex-center;

      height: 100%;
      padding: 0;
      font-size: 1.4rem;
      color: #3f3f3f;
    }
    @include mq($breakpoint: large) {
      font-size: 1.6rem;
    }
  }
}

.header-01-accordion {
  &__head {
    @include flex-bt;

    width: 100%;
    padding: 16px;
    border: none;
    font-size: 1.4rem;
    line-height: 1;
    color: #fff;

    appearance: none;

    @include mq {
      display: none;
    }
  }
  &__body {
    display: none;
    padding-left: 16px;

    @include mq {
      display: block;
      background: #989898;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      padding: 48px $md-grid-side-width;
      visibility: hidden;
      opacity: 0;
    }
  }

  &__inner {
    @include mq {
      display: flex;
      justify-content: space-between;
      max-width: $md-grid-column-width * $grid-columns + $md-grid-gutter-width *
      ($grid-columns - 1);
      margin: 0 auto;
    }
  }
}

.header-01-accordion-icon {
  position: relative;
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;

  appearance: none;

  @include mq {
    display: none;
  }
  &__line {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;

    @include mq {
    }
    &:first-child {
      transform: rotate(90deg);
    }
  }
}

.header-01-accordion-ttl {
  display: none;

  @include mq {
    display: block;
    line-height: 1;
    color: #fff;

    writing-mode: vertical-rl;
  }
  &__sub {
    font-size: 1.3rem;

    @include mq {
    }
  }
  &__main {
    margin-right: 16px;
    font-size: 2.6rem;
    font-weight: bold;
    letter-spacing: 0.02em;

    @include mq {
    }
  }
}

.header-01-accordion-line {
  display: none;

  @include mq {
    display: block;
    background: #fff;
    width: 2px;
    margin-left: 16px;
  }
}

.header-01-sub-list {
  @include mq {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 232px * 3 + 32px * 2 + 16px * 2;
    margin: -32px -16px 0 (-16px + 40px);
  }
  &__item {
    border-top: 1px solid #fff;

    @include mq {
      width: calc((100% - 32px * 2 - (32px / 2) * 2) / 3);
      margin: 32px 16px 0;
      border: none;
    }
  }
  &__link {
    display: block;

    @include mq {
    }
  }
  &__fig {
    @include mq {
      background: #fff;
      height: 120px;
    }
  }
  &__ttl {
    padding: 16px;
    line-height: 1;
    letter-spacing: 0.1em;
    color: #fff;

    @include mq {
      margin-top: 16px;
      padding: 0;
      font-size: 1.6rem;
      letter-spacing: 0.02em;
    }
  }
}

.header-01-menu {
  @include flex-center;

  background: #b5b5b5;
  width: $header-height;
  height: 100%;
  padding: 0;
  border: none;

  appearance: none;

  @include mq {
    display: none;
  }
}

.header-01-menu-icon {
  @include flex-bt;

  flex-direction: column;
  width: 18px;
  height: 12px;
  &__line {
    background: #fff;
    height: 1px;

    @include mq {
    }
    &:nth-child(1), &:nth-child(3) {
      width: 10px;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
}

.js-header.is-active {
  .header-01-list {
    visibility: visible;
    opacity: 1;
  }
}
